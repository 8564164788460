/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

export const LocationCard = props => {
  const { location } = props;
  return (
    <Card className={cx(props.className)}>
      <Card.Content id="location-q" className="location-content" href={_.get(location, 'slug')} as="a">
        <div className="title text-h4">
          {location.title}
        </div>
        <div className="detail text-p">
          {location.detail}
        </div>
      </Card.Content>
    </Card>
  );
};

export default styled(LocationCard)`
  .location-content {
    padding: 20px 10px;
    &#location-q {
      border-radius: 23px !important;
    }
    .title {
      text-align: center;
      font-weight: 600 !important;
      margin-bottom: 0px !important;
    }
    .detail {
      text-align: center;
      margin-bottom: 0px !important;
    }
  }
  height: 100%;
  box-shadow: none !important;
  &.type-1 {
    .location-content {
      background: ${props => props.theme.bg1} !important;
    }
    .detail,.title {
      color: ${props => props.theme.txtColor1} !important;
    }
  }
  &.type-2 {
    .location-content {
      background: ${props => props.theme.bg1} !important;
    }
    .detail,.title {
      color: ${props => props.theme.txtColor2} !important;
    }
  }
`;
