/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import cx from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import { useStaticQuery, graphql } from 'gatsby';

import { getOneOf, getNewsSlug } from '../../lib/utils';
import renderPara from '../../lib/renderPara';

const typeToColor = {
  'type-1': 'orange',
  'type-2': 'teal',
  'type-3': 'yellow',
  'type-4': 'pink',
  'type-5': 'orange',
}

const getLatestNews = (items) => {
  const now = moment().format("YYYY-MM-DD");
  const found = _.find(_.castArray(items || []), item => {
    return _.get(item, 'data.date_scheduled') <= now;
  });
  return found;
}

export const NewsCard = props => {
  const { item } = props;

  const newsData = useStaticQuery(graphql`
    query NewsQuery {
      data: allAirtable(filter: {table: {eq: "News"}, data: {status: {eq: "published"}}}, sort: {order: DESC, fields: data___date_scheduled}) {
        items: nodes {
          id
          data {
            tags
            status
            attachments {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
            date_scheduled
            description
            full_copy
            title
            post_type
          }
        }
      }
    }
  `);
  const latestNews = getLatestNews(_.get(newsData, 'data.items'));
  const newsVisibility = _.get(item, 'newsVisibility');

  if(!newsVisibility || !latestNews) {
    return null;
  }

  const className = getOneOf(item, ['className'], 'type-5');
  const actionText = 'View all News';
  const actionLink = '/news';
  const actionProps = {
    as: actionLink ? 'a' : Button,
    ...(actionLink ? { href: actionLink } : {}),
  };

  return (
    <div className={cx(props.className, className)}>
      <div className="header">
        <div className="header-title">
          Latest News.
        </div>
      </div>
      <div className="content">
        <div className="title">
          {renderPara(_.get(latestNews, 'data.title'))}
        </div>
        <div className="description text-p">
          {renderPara(_.get(latestNews, 'data.description'))}
        </div>
        <div className="detailLink">
          Read the full post <a href={getNewsSlug(latestNews.data)}>here</a>, or:
        </div>
        <Button
          className="button text-p"
          {...(_.get(typeToColor, className) && { color: _.get(typeToColor, className) }) }
          {...actionProps}
        >{actionText}</Button>

      </div>
    </div>
  );
};

export default styled(NewsCard)`
  position: relative;
  margin: 35px 10px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 20px 15px !important;
  }
  .header {
    padding: 20px 30px;
    border-radius: 8px 8px 0 0;
    border: 1px solid grey;
    border-bottom: none;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 20px 15px !important;
    }
    .header-title {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .content {
    padding: 10px 30px;
    border-radius: 0 0 8px 8px;
    border: 1px solid grey;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      padding: 10px 15px !important;
    }
    .header-title {
      font-size: 30px;
      font-weight: bold;
    }
  }
  .description {
    color: inherit;
    font-size: 16px;
    line-height: 24px;
  }
  .title {
    color: inherit;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .detailLink {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 20px;
    a {
      font-weight: 700;
      color: ${props => props.theme.txtTeal};
      text-decoration: underline;
    }
  }
  .button {
    color: inherit;
    padding: 2px 20px;
    font-weight: 300;
  }
  ${props => [
    { name: 'type-1', color: props.theme.txtOrange, bg: props.theme.bg1 },
    { name: 'type-2', color: props.theme.txtTeal, bg: props.theme.bg1 },
    { name: 'type-3', color: props.theme.txtYellow, bg: props.theme.bg1 },
    { name: 'type-4', color: props.theme.txtPink, bg: props.theme.bg1 },
  ].map(({ name, color, bg }) => `
    &.${name} {
      background: ${bg} !important;
      color: ${color};
      .button {
        border: 0.5px solid ${color};
      }
    }
  `).join('\n')}
  &.type-5 {
    .header {
      background: ${props => props.theme.bgTeal} !important;
      color: ${props => props.theme.primaryWhite};
      .header-title {
      }
    }  
  }
`;
