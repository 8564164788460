/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import LocationCard from './LocationCard';

export const CatList = props => {
  const { locations } = props;
  return (
    <div className={cx(props.className)}>
      <div className="title text-h3 txt-color-3">Do you live in Atlanta, Georgia?</div>
      <Grid >
        {
          _.castArray(locations || []).map((item, index) => {
            const catType = _.get(['type-1', 'type-2', 'type-3', 'type-4', 'type-5'], index % 4);
            return (
              <Grid.Column key={item.title} mobile={8} tablet={4} computer={4} className="col grid">
                <LocationCard location={item} className={catType}/>
              </Grid.Column>
            )
          })
        }
      </Grid>

    </div>
  )
};

export default styled(CatList)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0 15px !important;
  }
  margin: 20px 0px;
  > .title {
    font-weight: 700;
    padding-left: 10px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      /* display: none; */
    }
    margin-bottom: 0px !important;
  }
  .ui.grid > .col.column.grid {
    @media (max-width: ${({ theme }) => theme.mobile}) {
      width: 100% !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .card {
      width: 100%;
      margin-bottom: 5px !important;
      margin-top: 0 !important;
    }
    &:first-child {
      .card {
        @media (max-width: ${({ theme }) => theme.mobile}) {
          margin-top: 15px !important;
        }
      }
    }
  }

`;
